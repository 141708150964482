import { useState } from 'react';
import TabBar from '../../components/TabBar';
import LatestMessages from './LatestMessages';
import Users from './Users';
import Groups from './Groups';
import Conversation from './Conversation';
import Calendar from 'react-calendar';
import CurrencyConverter from '../../converter/Converter/CurrencyConverter';



import { Container, Divider, Grid, ListSubheader, Typography, List, ListItem, ListItemText } from '@material-ui/core';
import { useMainPageStyles } from '../../styles/muiStyles';
import Feed from '../../components/Feed';
import { today } from '../../context/auth';

const Main = () => {
  const classes = useMainPageStyles();
  const [value, onChange] = useState(new Date());
  const [tab, setTab] = useState('chat');


  return (
    <>
      <Grid container >
        <Grid item xs={12} md={2}>
          <ListItem style={{ background: "linear-gradient(#9d2654, #592c66)" }} >
            <Typography
              // color={"primary"}
              style={{ color: "white" }}
              variant="h6"
              component="h2"
            >
              Currency Converter
            </Typography>
          </ListItem>
          <Divider />
          <CurrencyConverter />

        </Grid>
        <Grid item xs={12} md={8} style={{ boxShadow: "0px 0px 15px 0px rgba(89,44,102,0.93)" }}>
          <Container style={{ padding: 0 }}>
            <div className={classes.root}>
              <div className={classes.leftPanel}>
                <TabBar tab={tab} setTab={setTab} />
                <div className={classes.leftPanelContent}>
                  {tab === 'chat' ? (
                    <LatestMessages />
                  ) : tab === 'users' ? (
                    <Users />
                  ) : (
                    <Groups />
                  )}
                </div>
              </div>
              <Conversation />
            </div>
          </Container>
        </Grid>
        <Grid item xs={12} md={2}>
          <ListItem style={{ background: "linear-gradient(#9d2654, #592c66)" }} >
            <Typography
              // color={"primary"}
              style={{ color: "white" }}
              variant="h6"
              component="h2"
            > 
            Templates
            </Typography>
          </ListItem>
          <Divider />
          <Feed />
        </Grid>
      </Grid>



    </>
  );
};

export default Main;
