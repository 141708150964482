import { MenuItem, TextField } from '@material-ui/core'
import React from 'react'

function Dropdowns({ labelName, handleChange, value }) {
    return (

        <>
            <TextField
                className='form-select bg-dark custom-select form-select-lg text-white border-dark shadow'
                value={value}
                fullWidth
                variant="outlined"
                label={labelName}
                onChange={event => handleChange(event)}
                select
            >
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="EUR">EUR</MenuItem>
                <MenuItem value="PHP">PHP</MenuItem>
                <MenuItem value="INR">INR</MenuItem>
                <MenuItem value="AUD">AUD</MenuItem>
                <MenuItem value="INR">INR</MenuItem>
                <MenuItem value="PKR">PKR</MenuItem>
                <MenuItem value="NZD">NZD</MenuItem>
                <MenuItem value="CAD">CAD</MenuItem>
                <MenuItem value="CHF">CHF</MenuItem>
                <MenuItem value="JPY">JPY</MenuItem>
                <MenuItem value="BGN">BGN</MenuItem>
                <MenuItem value="ILS">ILS</MenuItem>
                <MenuItem value="RUB">RUB</MenuItem>
                <MenuItem value="ZAR">ZAR</MenuItem>
                <MenuItem value="TRY">TRY</MenuItem>
                <MenuItem value="HKD">HKD</MenuItem>
                <MenuItem value="MYR">MYR</MenuItem>
                <MenuItem value="THB">THB</MenuItem>
                <MenuItem value="HRK">HRK</MenuItem>
                <MenuItem value="NOK">NOK</MenuItem>
                <MenuItem value="IDR">IDR</MenuItem>
                <MenuItem value="DKK">DKK</MenuItem>
                <MenuItem value="HUF">HUF</MenuItem>
                <MenuItem value="MXN">MXN</MenuItem>
                <MenuItem value="KRW">KRW</MenuItem>
                <MenuItem value="ISK">ISK</MenuItem>
                <MenuItem value="SGD">SGD</MenuItem>
                <MenuItem value="BRL">BRL</MenuItem>
                <MenuItem value="PLN">PLN</MenuItem>
                <MenuItem value="RON">RON</MenuItem>
                <MenuItem value="CNY">CNY</MenuItem>
                <MenuItem value="SEK">SEK</MenuItem>
            </TextField>

        </>
    )
}

export default Dropdowns
