import { useAuthContext } from '../../context/auth';
import { formatTime } from '../../utils/helperFuncs';

import { Card, Typography } from '@material-ui/core';
import PDF from '../../png/pdf.png';
import { useConversationPageStyles } from '../../styles/muiStyles';

import backendUrls from '../../backendUrls';
import Image from '../../components/Image';
import Audio from '../../components/Audio';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const { file } = backendUrls;


const MessageBubble = ({ message }) => {

  const classes = useConversationPageStyles();
  const { user } = useAuthContext();

  const isSentMsg = message.senderId === user.id;
  const isAudio = message.type === "Audio";
  const isImage = message.type === "Image";
  const isText = message.type === "Text";
  const isTemplate = message.type === "Template";
  const isAttachment = message.type === "Attachment";




  return (
    <div className={classes.messageWrapper}>
      <div style={!isText && !isTemplate && { background: "none", padding: 0 } || {}} className={isSentMsg ? classes.sentMsg : classes.receivedMsg}>
        {isAudio ?
          <Audio url={`${file}${message.body}`} time={formatTime(message.createdAt)} /> :
          isImage ?
            <Image url={`${file}${message.body}`} time={formatTime(message.createdAt)} /> :
            isTemplate ?
              ReactHtmlParser(message.body) :
              isAttachment ?
                <a href={`${file}${message.body}`} target="_blank" >
                  <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                    <img src={PDF} style={{ width: 150 }} />
                    <Typography style={{ float: "right" }} variant="caption" className={classes.msgTime}>
                      {formatTime(message.createdAt)}
                    </Typography>
                  </div>
                </a>
                :
                <div className={classes.msgText} >{message.body}</div>}
        {(isText || isTemplate) &&
          <div style={{ display: 'flex', flexDirection: "row", gap: 2 }}>
            <Typography style={{ float: "right" }} variant="caption" className={classes.msgTime}>
              {formatTime(message.createdAt)}
            </Typography>
          </div>
        }
      </div>

    </div>

  );
};

export default MessageBubble;
