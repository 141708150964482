import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import apolloClient from './apolloClient';
import { AuthProvider } from './context/auth';
import { StateProvider } from './context/state';
import { Beforeunload } from 'react-beforeunload';
const storageKeyToken = 'cooljwt';
let user = localStorage.getItem(storageKeyToken);




ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <Router>
      <AuthProvider>
        <StateProvider>
        {/* <Beforeunload onBeforeunload={(event)=> !user ? event.preventDefault() :'You’ll lose your data!'} > */}
          <App />
          {/* </Beforeunload> */}
        </StateProvider>
      </AuthProvider>
    </Router>
  </ApolloProvider>,
  document.getElementById('root')
);
