// const backendUrls = {
//   http: 'http://192.168.18.33:3006/',
//   ws: 'ws://192.168.18.33:3006/graphql',
//   file: 'http://192.168.18.33:3007/',

//   // http: 'http://localhost:3006/',
//   // ws: 'ws://localhost:3006/graphql',
//   // file: 'http://localhost:3007/',
//   invoicemate: 'https://lite.invoicemate.net',
//   self:"https://chat.invoicemate.net"
// };


  

// export default backendUrls;

const backendUrls = {
  http: 'https://chat-api.invoicemate.net/',
  // ws: 'wss://chat.invoicemate.net/api/wss',
  ws: 'wss://chat-api.invoicemate.net/wss',
  file: 'https://chat-api.invoicemate.net/files/',

  // http: 'http://localhost:3006/',
  // ws: 'ws://localhost:3006/graphql',
  // file: 'http://localhost:3007/',
  invoicemate: 'https://app.invoicemate.net',
  self:"https://chat.invoicemate.net/"
};


  

export default backendUrls;


