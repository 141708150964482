import React, { Component } from "react";
import { API_DOMAIN, API_KEY, URL } from "../config/api";
import Dropdowns from "../components/Dropdowns";
import Convertresult from "../components/Convertresult";
import {
  Card,
  CardHeader,
  TextField,
  Typography,
  IconButton,
  Button,
  List,
  Divider,
} from "@material-ui/core";
import ShuffleIcon from "@material-ui/icons/Shuffle";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import ImportExportIcon from "@material-ui/icons/ImportExport";
class CurrencyConverter extends Component {
  constructor(props) {
    super(props);
    this.default = {
      from: "USD",
      into: "PKR",
      loading: false,
      amount: 1,
      conversionResult: "",
      conversionRate: "",
    };
    this.state = this.default;
  }

  convertCurrency = async ({ from, into, amount }) => {
    this.setState({ loading: true });
    let url = `https://data.fixer.io/api/convert?access_key=${API_KEY}&to=${into}&from=${from}&amount=${1}`;
    let data = await fetch(url);
    let parsedData = await data.json();
    const conversionRate = parsedData.result;
    const conversionResult = conversionRate * amount;
    this.setState({
      conversionRate: conversionRate?.toFixed(2) || 0,
      conversionResult: conversionResult?.toFixed(2) || 0,
      loading: false,
    });
  };

  handleInput = (event) => {
    this.setState({ amount: event.target.value });
  };

  handleFrom = (event) => {
    if (event.target) {
      this.setState({ from: event.target.value });
    } else {
      this.setState({ from: event.currentTarget.value });
    }
  };

  handleInto = (event) => {
    if (event.target) {
      this.setState({ into: event.target.value });
    } else {
      this.setState({ into: event.currentTarget.value });
    }
  };

  handleReset = () => {
    this.setState(this.default);
  };

  handleSwitch = () => {
    const { from, into } = this.state;
    this.setState({ from: into, into: from });
  };

  render() {
    const { from, into, amount, conversionRate, conversionResult, loading } =
      this.state;
    return (
      <>
        <List></List>
        {/* <Divider /> */}
        <Card style={{ padding: 10 }}>
          <TextField
            className="form-control-lg mt-5 shadow amount bg-dark"
            label="Enter Amount"
            value={amount}
            fullWidth
            variant="outlined"
            type="number"
            onChange={this.handleInput}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 25,
            }}
          >
            <div className="fromdrop">
              <Dropdowns
                labelName="From"
                handleChange={this.handleFrom}
                value={from}
              ></Dropdowns>
            </div>
            <IconButton onClick={this.handleSwitch}>
              <ImportExportIcon
                style={{ transform: "rotate(90deg) scale(1.5)" }}
              />
            </IconButton>
            <div className="intodrop">
              <Dropdowns
                labelName="Into"
                handleChange={this.handleInto}
                value={into}
              ></Dropdowns>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 25,
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              disabled={amount === "0" || amount === "" || amount < 0}
              onClick={() => this.convertCurrency(this.state)}
            >
              Convert
            </Button>
            {/* <IconButton
                        title='Convert'
                        disabled={amount === "0" || amount === "" || amount < 0}
                        onClick={() => this.convertCurrency(this.state)}>
                        <ShuffleIcon />
                    </IconButton> */}
            {/* <IconButton
                            title='Reset'
                            onClick={this.handleReset}
                        >
                            <RotateLeftIcon />
                        </IconButton> */}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 25,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Convertresult
              Loading={loading}
              result={conversionResult}
              rate={conversionRate}
            ></Convertresult>
          </div>
        </Card>
      </>
    );
  }
}

export default CurrencyConverter;
